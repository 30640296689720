<template>
    <div class="cookie-modal-wrapper">
        <button class="small cookie-settings-btn" @click="openCookieSettings">
            <span class="inner">Manage cookies</span>
        </button>
        <portal to="cookie-modal-area">
            <Modal :show="cookieModalShown" class="cookie-settings-modal" @close="closeCookieSettings">
                <template v-slot:close-btn>
                    <button class="button-modal-close" @click="closeCookieSettings">
                        <svg class="icon">
                            <title>Close</title>
                            <use xlink:href="#close"></use>
                        </svg>
                    </button>
                </template>
                <template v-slot:title>
                    Cookie policy settings
                </template>
                <template v-slot:body>
                    <section class="bordered-section">
                        <h3>Functional cookies</h3>
                        <p>Functional cookies are essential to enable you to move around this website and use its features and/or services. These cookies allow this website to provide services at the request of users and can't be turned off.</p>

                        <switch-button v-model="functionalCookiesOn" class="disabled">
                            <span class="switch-label">Required</span>
                        </switch-button>
                    </section>

                    <section class="analysis-section">
                        <h3>Analysis cookies</h3>
                        <p>Analysis cookies allow us to update this website to cater for user preferences and improve performance. They collect information about how this website is used, e.g. which pages users visit most often and where error messages are delivered. These collect and process information at an aggregated and anonymous level.</p>

                        <switch-button v-model="analysisCookiesOn">
                            <span class="switch-label" v-if="analysisCookiesOn">Enabled</span>
                            <span class="switch-label" v-else>Disabled</span>
                        </switch-button>
                    </section>

                    <div class="button-wrapper">
                        <button class="button xsmall" @click="saveCookieSettings">
                            <span class="inner">SAVE COOKIE SETTINGS</span>
                        </button>
                    </div>
                </template>
            </Modal>
        </portal>
    </div>
</template>

<script>
import Modal from '@/components/ui/Modal.vue';

export default {
    name: 'CookieModal',
    components: {
        Modal
    },
    data () {
        return {
            cookieModalShown: false,
            functionalCookiesOn: true,
            analysisCookiesOn: false,
            currentDomain: window.location.hostname
        };
    },
    computed: {
        isHttps () {
            if (window.location.protocol !== 'https:') {
                return null;
            }
            return 'Secure';
        }
    },
    methods: {
        closeCookieSettings () {
            this.cookieModalShown = false;
            document.body.classList.remove('overlay-open');
        },
        openCookieSettings () {
            this.cookieModalShown = true;
            document.body.classList.add('overlay-open');
        },
        saveCookieSettings () {
            const nowEpochSeconds = Math.floor(Date.now() / 1000);
            localStorage.setItem('pfMaterialsCookiesConfirmed', nowEpochSeconds);
            if (this.analysisCookiesOn) {
                localStorage.setItem('pfMaterialsAnalyticsCookiesConfirmed', nowEpochSeconds);
                // Enable google GTM tracking
                this.$gtm.enable(true);
            } else {
                // Remove analytics cookie preference
                localStorage.removeItem('pfMaterialsAnalyticsCookiesConfirmed');
                // Remove google analytics cookies
                this.$cookies.keys().forEach(name => {
                    if (['_ga', '_gid', '_gat'].some(keyword => name.includes(keyword))) {
                        document.cookie = name+'=; Path=/; Domain='+this.currentDomain+'; expires=Thu, 01 Jan 1970 00:00:00 UTC; '+this.isHttps+';';
                    }
                });

                // Disable google GTM tracking
                this.$gtm.enable(false);
            }

            this.$emit('cookieSettingsUpdated', nowEpochSeconds);
            this.closeCookieSettings();
        },
    },
    mounted () {
        if (localStorage.getItem('pfMaterialsAnalyticsCookiesConfirmed')) {
            this.analysisCookiesOn = true;
        }

        document.addEventListener('keydown', (e) => {
            if (this.cookieModalShown && e.keyCode === 27) {
                this.closeCookieSettings();
            }
        });
    }
};
</script>

<style scoped lang="sass">
    .cookie-settings-modal
        *
            text-align: left

        .analysis-section
            padding-top: $site-padding-md
            padding-bottom: $site-padding-md

        h4 + *,
        p + *
            margin-top: $site-padding-sm

    .switch-button
        margin-top: $site-padding-sm
        position: relative

    .switch-label
        position: absolute
        left: 100%
        top: 0
        margin-left: $site-padding-sm
    
    .cookie-settings-btn
        text-transform: uppercase

</style>
