<template>
    <div class="cookie-consent" v-if="!cookiesConfirmedDate" role="dialog" aria-label="Cookie Consent">
        <div class="cookies-overlay">
            <div class="cookie-bar">
                <div class="cookie-bar-header" v-if="!onContentPages">
                    <div class="structure">
                        <a href="/">
                            <img alt="Pierre Fabre" src="@/assets/images/logo.svg" class="logo">
                        </a>
                    </div>
                </div>

                <div class="structure cookie-structure">
                    <div v-if="onContentPages" class="logo-area">
                        <img src="@/assets/images/logo.svg" alt="Pierre Fabre" class="logo">
                        <img src="@/assets/images/logo-small.svg" alt="Pierre Fabre" class="logo-small">
                    </div>

                    <div class="copy-wrapper">
                        <div class="copy">
                            <h4>Cookie policy</h4>
                            <p>Pierre Fabre UK and Republic of Ireland uses cookies to help give you the best possible experience on our site. These support site navigation, analysis of site performance, and personalisation of content. Some are necessary for our site to function, others are optional but could impact the features we offer. For more information about the processing of your personal data and your rights, see our <router-link to="/cookie-policy">Cookie policy</router-link>. To set or disable cookies, click the "Manage cookies" button.</p>
                        </div>
                        <cookie-modal @cookieSettingsUpdated="updateCookieConfirmedDate" />
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CookieModal from '@/components/ui/CookieModal.vue';

export default {
    name: 'CookieBar',
    components: {
        CookieModal
    },
    data () {
        return {
            cookiesConfirmedDate: null,
            cookieSettingsShown: false,
            functionalCookiesOn: true,
            cookieExpiryDays: 30
        };
    },
    props: {
        onContentPages: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        cookieExpirySeconds: function() {
            return this.cookieExpiryDays * 24 * 60 * 60;
        },
        confirmedEpochSecondsInt: function () {
            return parseInt(this.cookiesConfirmedDate);
        },
        expiryEpochSecondsInt: function () {
            return this.confirmedEpochSecondsInt + this.cookieExpirySeconds;
        },
        nowEpochSeconds: function () {
            return Math.floor(Date.now() / 1000);
        },
        cookiesHaveExpired: function () {
            if (this.nowEpochSeconds > this.expiryEpochSecondsInt) {
                return true;
            }
            return false;
        }
    },
    methods: {
        showCookieSettings () {
            this.cookieSettingsShown = true;
            document.body.classList.add('overlay-open');
        },
        updateCookieConfirmedDate (newCookieConfirmedDate) {
            this.cookiesConfirmedDate = newCookieConfirmedDate;
        },
        deleteCookieSettings () {
            localStorage.removeItem('pfMaterialsCookiesConfirmed');
            localStorage.removeItem('pfMaterialsAnalyticsCookiesConfirmed');
        }
    },
    mounted () {
        this.cookiesConfirmedDate = JSON.parse(localStorage.getItem('pfMaterialsCookiesConfirmed'));

        if (this.cookiesHaveExpired) {
            this.deleteCookieSettings();
        }
    }
};
</script>

<style scoped lang="sass">
    .cookies-overlay
        background: rgba(#5A5857, 0.6)
        z-index: $zindex-cookiebar
        position: fixed
        left: 0
        right: 0
        bottom: 0
        top: 0

        // we still need to allow site to be used, so the
        // cookies aren't seen as blocking the site
        pointer-events: none

    .cookie-structure
        padding-top: $site-padding
        padding-bottom: $site-padding

        .drug-site-cookie-bar &
            max-width: $max-site-width-content

            @include media($media-collapsed-menu)
                display: flex
                align-items: center

        @include media($media-width-large)
            display: flex
            align-items: flex-end

    .cookie-bar
        position: absolute
        top: 0
        left: 0
        right: 0
        width: 100%
        background: #fff
        pointer-events: all
        box-shadow: 0 6px 18px 0 rgba(#000, 0.2)

        *
            font-size: $font-size-sm

        h4 + *
            margin-top: 8px

        a
            font-weight: normal
            color: $cerulean !important

        .copy
            @include media($media-width-large)
                padding-right: $col-width-large

        .copy-wrapper
            @include media($media-width-large)
                display: flex
                align-items: flex-end

        .cookie-modal-wrapper
            flex-shrink: 0
            margin-top: $site-padding

            @include media($media-width-large)
                margin-top: 0

                .drug-site-cookie-bar &
                    margin-top: 10px
                    align-self: center

        button
            font-size: $font-size-xsm
            text-transform: uppercase

            .drug-site-cookie-bar &
                font-size: $font-size-base
                text-transform: none

    .logo-area
        flex-shrink: 0
        align-self: flex-start

        .logo
            display: block
            width: $content-logo-md-w
            height: $content-logo-md-h
            margin: 0 auto $site-padding

            @include media($media-width-medium)
                margin-top: $site-padding

            @include media($media-collapsed-menu)
                display: none

            @include media($media-expanded-menu)
                display: block
                width: $content-logo-lg-w
                height: $content-logo-lg-h
                margin: $site-padding 64px 0 $site-padding-md

        .logo-small
            width: $content-logo-sm-w
            height: $content-logo-sm-w
            margin-right: $site-padding-md
            display: none

            @include media($media-collapsed-menu)
                display: block

            @include media($media-expanded-menu)
                display: none

</style>
