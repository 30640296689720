<template>
    <div class="accordion">
        <div
            class="accordion-item"
            :class="{ 'open' : i === activeQuestionIndex }"
            v-for="(item, i) in items"
            :key="`accordion-item-${i}`"
        >
            <div @click="makeActive(i)" role="button" class="accordion-header">
                <div class="icon-circle" v-if="item.icon">
                    <svg class="icon">
                        <use :xlink:href="'#'+ item.icon"></use>
                    </svg>
                </div>
                <h2 v-html="item.title" class="title"></h2>
                <svg class="icon chevron">
                    <use xlink:href="#chevron"></use>
                </svg>
            </div>
            <slide-up-down :active="i === activeQuestionIndex">
                <div class="accordion-content">
                    <slot v-bind="item">
                        <div v-html="item.content"></div>
                    </slot>
                </div>
            </slide-up-down>
        </div>
    </div>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down';
export default {
    name: 'Accordion',
    components: {
        SlideUpDown
    },
    data () {
        return {
            activeQuestionIndex: this.initialQuestionIndex
        };
    },
    props: {
        /* Item object example: {title: string, content: string, icon: string} */
        items: {
            type: Array,
            required: true
        },
        initialQuestionIndex: {
            type: Number,
            default: 0
        }
    },
    methods: {
        makeActive (itemIndex) {
            this.activeQuestionIndex = this.activeQuestionIndex === itemIndex ? null : itemIndex;
            this.$emit('itemSelect', { itemIndex });
        },
    }
};
</script>

<style lang="sass">
.accordion-header
    display: flex
    position: relative
    align-items: center

    .icon-circle
        width: $icon-circle-x-sm
        height: $icon-circle-x-sm
        padding: 7px
        margin-right: $site-padding-sm

    h2.title
        font-size: $font-size-base
        color: $graphite
        transition: color 0.2s
        -webkit-font-smoothing: antialiased
        margin: 0
        flex-grow: 1

    .chevron
        width: 14px
        height: 8px
        margin: 0
        margin-left: $site-padding-xs
        transition: transform 0.2s ease-in-out
        flex-shrink: 0

.accordion-header,
.accordion-content
    padding: $site-padding
    transition: background-color 0.2s

.accordion-item
    border-top: $accordion-border
    border-bottom: $accordion-border

    + .accordion-item
        border-top: 0

    &.open
        .accordion-header
            background-color: $linen

        h2
            color: $navy

            .theme-mektovi &
                color: $teal

            .theme-nerlynx &
                color: $magenta-acc

        .chevron
            transform: rotate(180deg)

        .accordion-content
            background-color: $accordion-bg-color

            .theme-nerlynx &
                background-color: $nerlynx-light-sand

.accordion
    &.nx-side-effects
        li
            display: flex
            align-items: center
            padding-left: 0

            .icon
                flex-shrink: 0
                width: 32px
                height: 32px
                margin-right: 8px

            .icon-fainting
                width: 24px
                height: 24px
                margin-left: 4px

            &::before
                content: none
</style>
