<template>
    <div class="icon-block">
        <div class="icon-circle">
            <svg class="icon">
                <use :xlink:href="'#'+ icon"></use>
            </svg>
        </div>
        <p v-if="icon"></p>
        <div class="desc">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IconBlock',
    props: {
        icon: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="sass">
.icon-block
    display: flex
    max-width: 650px
    margin-top: $site-padding-md

    .theme-nerlynx &
        .desc
            display: flex
            align-items: center
            padding-top: 0

    &.wide
        max-width: 850px

    .icon-circle
        width: $icon-circle-sm
        height: $icon-circle-sm
        flex-shrink: 0
        margin-right: $site-padding-sm

    .desc
        margin-top: 0
        padding-top: 6px

    ul
        margin: 0

    p
        + ul
            margin: $site-padding-sm 0
</style>
