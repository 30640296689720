<template>
    <div class="public">
        <cookie-bar></cookie-bar>

        <SimpleHeader />

        <main>
            <div class="structure">
                <back-to-home />
                <privacy-policy class="legal-content" />
                <div class="page-job-code">Job code: {{ $route.meta.jobCode }} | {{ dateOfPrep }}</div>
            </div>
        </main>
    </div>
</template>

<script>
import SimpleHeader from '@/components/layout/SimpleHeader.vue';
import BackToHome from '@/components/layout/BackToHome.vue';

import PrivacyPolicy from '@/components/legal/PrivacyPolicy.vue';

export default {
    name: 'PublicPrivacyPage',
    components: {
        BackToHome,
        SimpleHeader,
        PrivacyPolicy
    },
    data () {
        return {
            dateOfPrep: 'March 2023'
        };
    }
};
</script>
