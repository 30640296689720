<template>
    <div class="link-card">
        <header>
            <router-link :to="{ name: contentSectionLinks[0].name}">
                <svg v-if="showIcon" class="icon">
                    <use xlink:href="#download"></use>
                </svg>
                <span v-else class="icon-circle">{{ number }}</span>
                <h2 class="h-medium-normal">{{ title }}</h2>
            </router-link>
        </header>

        <ul v-if="showChildLinks">
            <li v-for="item in contentSectionLinks" :key="item.name">
                <router-link :to="{ name: item.name}"><span v-html="item.meta.title"></span></router-link>
            </li>
        </ul>

        <div v-else>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LinkCard',
    props: {
        title: {
            type: String,
            default: 'Side effects'
        },
        number: {
            type: String,
            default: '3'
        },
        showNumber: {
            type: Boolean,
            default: true
        },
        showIcon: {
            type: Boolean,
            default: false
        },
        showChildLinks: {
            type: Boolean,
            default: true
        },
        parentSection: {
            type: String,
            default: null
        },
        contentSection: {
            type: String,
            default: null
        }
    },
    computed: {
        parentRoute() {
            return this.$router.options.routes.find(route => route.name === this.parentSection);
        },
        currentChildren() {
            return this.parentRoute.children;
        },
        contentSectionLinks() {
            return this.currentChildren.filter(route => route.meta.section === this.contentSection);
        }
    }
};
</script>

<style lang="sass">
    .link-card
        display: block
        padding: $site-padding
        background: $linen

        @include media($media-width-large)
            padding: $site-padding $site-padding-md

        header
            display: flex
            align-items: center
            padding-bottom: $site-padding
            border-bottom: $grey-border
            margin-bottom: $site-padding

            a
                width: 100%
                justify-content: flex-start
                margin-bottom: 0

                &::after
                    margin-left: auto

        h2
            margin: 0

        .icon-circle
            color: $white
            font-size: 23px
            margin-right: $site-padding-sm
            flex-shrink: 0
            width: $icon-circle-sm
            height: $icon-circle-sm

            @include media($media-width-large)
                font-size: 32px
                width: $icon-circle-lg
                height: $icon-circle-lg

            .theme-nerlynx &
                background: $magenta-acc
                border: 0

        .icon
            margin-right: $site-padding-sm
            flex-shrink: 0
            width: $icon-circle-sm
            height: $icon-circle-sm

            @include media($media-width-large)
                width: $icon-circle-lg
                height: $icon-circle-lg

        ul
            list-style: none
            margin: 0
            padding: 0

        li
            padding: 0

            &::before
                display: none

        a,
        .external-link-wrapper a
            color: $text-color
            text-decoration: none
            display: flex
            justify-content: space-between
            align-items: center
            margin-bottom: $site-padding-sm
            transition: color $transition
            font-size: $font-size-sm

            @include media($media-width-large)
                font-size: $font-size-base

            &::after
                content: ''
                display: block
                width: 20px
                height: 20px
                background: url('~@/assets/images/arrow-blue.svg')
                    size: contain
                    position: center
                    repeat: no-repeat
                margin-left: $site-padding-xs
                opacity: 0
                transition: opacity $transition
                flex-shrink: 0

                .theme-mektovi &
                    background-image: url('~@/assets/images/arrow-green.svg')

                .theme-nerlynx &
                    background-image: url('~@/assets/images/arrow-magenta.svg')

            &:hover,
            &:focus,
            &:active
                color: darken($text-color, 25%)

                &::after
                    opacity: 1
</style>
