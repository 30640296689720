import Vue from 'vue';
import App from './App.vue';
import router from './router';

// Meta tags and SEO
import VueMeta from 'vue-meta';
Vue.use(VueMeta);

let isEnabled;

if (localStorage.getItem('pfMaterialsAnalyticsCookiesConfirmed')) {
    isEnabled = true;
} else {
    isEnabled = false;
}

// Google Analytics
import VueGtm from '@gtm-support/vue2-gtm';
Vue.use(VueGtm, {
    id: process.env.VUE_APP_GA_CODE,
    anonymize_ip: true,
    enabled: isEnabled,
    vueRouter: router
});

// Vue cookies
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

// Vue portal for rendering DOM outside of a component
import PortalVue from 'portal-vue';
Vue.use(PortalVue);

// UI components
import AccordionBlock from './components/ui/Accordion';
import AlertBlock from './components/ui/AlertBlock';
import ContactDetailsBlock from './components/ui/ContactDetailsBlock';
import ContentBlock from './components/ui/ContentBlock';
import IconBlock from './components/ui/IconBlock';
import ResourceBlock from './components/ui/ResourceBlock';
import QuestionAnswerBlock from './components/ui/QuestionAnswerBlock';
import LinkCard from './components/ui/LinkCard';
import ExternalLink from './components/ui/ExternalLink';
import SwitchButton from './components/ui/SwitchButton';

import CookieBar from '@/components/layout/CookieBar.vue';

Vue.component('AccordionBlock', AccordionBlock);
Vue.component('AlertBlock', AlertBlock);
Vue.component('ContactDetailsBlock', ContactDetailsBlock);
Vue.component('ContentBlock', ContentBlock);
Vue.component('IconBlock', IconBlock);
Vue.component('ResourceBlock', ResourceBlock);
Vue.component('QuestionAnswerBlock', QuestionAnswerBlock);
Vue.component('LinkCard', LinkCard);
Vue.component('ExternalLink', ExternalLink);
Vue.component('SwitchButton', SwitchButton);

Vue.component('CookieBar', CookieBar);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
    let isProtected = to.matched.some(m => m.meta.patientProtected);
    let patientConfirmed = Vue.$cookies.get('pfMaterialsPatientConfirmed');

    if (isProtected && !patientConfirmed) {
        router.push({ name: 'LandingPage' });
    }

    next();
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
