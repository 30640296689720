<template>
    <div id="app">
        <IconSet />

        <router-view />

        <Footer v-if="requiresGlobalFooter" :class="extraClass" :global-footer="true" />

        <portal-target name="modal-area" multiple />
        <portal-target name="cookie-modal-area" multiple />
    </div>
</template>

<script>
import IconSet from '@/components/ui/IconSet.vue';
import Footer from '@/components/layout/Footer.vue';

export default {
    name: 'App',
    components: {
        Footer,
        IconSet
    },
    computed: {
        requiresGlobalFooter() {
            return this.$route.meta.requiresGlobalFooter;
        },
        extraClass() {
            return this.$route.meta.extraClass;
        }
    },
    metaInfo() {
        return {
            title: 'Pierre Fabre Oncology UK and Ireland Patient Website',
            titleTemplate: '%s | Pierre Fabre Oncology UK and Ireland Patient Website'
        };
    },
    watch: {
        '$route' () {
            if (!localStorage.getItem('pfMaterialsAnalyticsCookiesConfirmed')) {
                // opt out of tracking if analytics consent has been manually deleted
                this.$gtm.enable(false);
            }
        }
    }
};
</script>

<style lang="sass">
@import url('https://use.typekit.net/bnf8tph.css')

#app
    font-family: $font-family-base
    color: $text-color
</style>
