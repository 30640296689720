<template>
    <div class="content-block">
        <img :src="require('@/assets/images/' + imgName + '')" :alt="imgAlt" v-if="imgName">
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContentBlock',
    props: {
        imgAlt: {
            type: String,
            default: null
        },
        imgName: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="sass">
.content-block
    &.image-left,
    &.image-right
        display: flex
        flex-direction: column
        align-items: center

        @include media($media-width-large)
            align-items: flex-start
            flex-direction: row

        img
            max-width: 165px
            flex-shrink: 0

            + div
                padding-top: $site-padding-sm

                @include media($media-width-large)
                    padding-top: 0
                    padding-left: $site-padding-md

    &.image-right
        @include media($media-width-large)
            img
                order: 2

                + div
                    padding-left: 0
                    padding-right: $site-padding-md

    &.image-center
        text-align: center
        img
            max-width: 100px
            max-height: 100px

    &.nerlynx-tablets
        text-align: center

        img
            max-width: 227px

    &.nerlynx-pack
        text-align: center

        img
            max-width: 178px

    &.aligned-center
        @include media($media-width-large)
            align-items: center

    &.bordered
        padding: $site-padding-md
        border: 3px solid $navy
        border-left-width: 0
        border-right-width: 0

        .theme-mektovi &
            border-color: $teal
            border-top-width: 5px
            border-bottom-width: 5px

        .theme-braftovi &
            border-image: $bar-gradient 1

        &.image-left
            align-items: center

        + *
            margin-top: $site-padding-md

* + .bordered
    margin-top: $site-padding-md

</style>
