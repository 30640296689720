<template>
    <div class="switch-button" :class="{ enabled: isEnabled }" @click="toggle">
        <div class="switch" role="button" aria-label="toggle setting"></div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'SwitchButton',
    model: {
        prop: 'isEnabled',
        event: 'toggle'
    },
    props: {
        isEnabled: Boolean
    },
    methods: {
        toggle: function () {
            this.$emit('toggle', !this.isEnabled);
        }
    }
};
</script>

<style scoped lang="sass">
    .switch-button
        background-color: rgba($landing-grey, 0.3)
        border-radius: 22px
        padding: 4px
        margin: 0
        width: 60px
        transition: background-color 0.3s ease-in-out
        cursor: pointer

        &.enabled
            background-color: $navy

            .switch
                transform: translate3d(31px, 0, 0)

        &.disabled
            pointer-events: none
            background-color: rgba($navy, 0.2)

    .switch
        border-radius: 100%
        width: 20px
        height: 20px
        background: #fff
        box-shadow: 0 0 2px 0 rgba(#000, 0.14), 0 2px 2px 0 rgba(#000, 0.12), 0 1px 3px 0 rgba(#000, 0.2)
        transition: transform 0.3s ease-in-out
</style>
