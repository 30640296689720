<template>
    <div class="resource-block">
        <div class="resource-block-content">
            <h3 class="h-medium-normal" v-html="title"></h3>
            <p>
                <slot></slot>
            </p>
        </div>
        <a
            class="button button-with-icon"
            :href="'/downloads/' + download"
            target="_blank"
            rel="noreferrer noopener"
            v-if="download"
        >
            <svg class="icon button-icon">
                <use xlink:href="#download"></use>
            </svg>
            <span v-html="'Download '+title"></span>
        </a>
        <external-link
            class="button button-with-icon"
            :href="externalResource"
            v-if="externalResource"
        >
            <svg class="icon button-icon">
                <use xlink:href="#external-resource"></use>
            </svg>
            <slot name="button-text">
                <span v-html="'View '+title"></span>
            </slot>
        </external-link>
    </div>
</template>

<script>
export default {
    name: 'ResourceBlock',
    props: {
        title: {
            type: String,
            default: 'Resource'
        },
        download: {
            type: String,
            default: null
        },
        externalResource: {
            type: String,
            default: null
        }
    }
};
</script>

<style scoped lang="sass">
    .resource-block
        @include font-smoothing
        display: flex
        flex-direction: column
        justify-content: space-between
        color: $white

        background:
            size: cover
            position: top center
            repeat: no-repeat

        .theme-braftovi &
            background-image: url('~@/assets/images/resource-bg-blue.png')

            h3
                color: $white

        .theme-mektovi &
            background-image: url('~@/assets/images/resource-bg-green.png')

            h3
                color: $white

        .theme-nerlynx &
            background-image: url('~@/assets/images/resource-bg-magenta.png')

            h3
                color: $white

        h3
            text-align: center

        .button-with-icon
            display: flex
            padding: 16px 32px

            .icon
                fill: #fff
                flex-shrink: 0

            .theme-braftovi &
                background: $ink

                &:hover,
                &:active,
                &:focus
                    background: $button-dark-braftovi

            .theme-mektovi &
                background: $teal-dark

                &:hover,
                &:active,
                &:focus
                    background: $teal-extra-dark

            .theme-nerlynx &
                background: $button-hover-nerlynx

                &:hover,
                &:active,
                &:focus
                    background: darken($button-hover-nerlynx, 8%)

    .resource-block-content
        padding: $site-padding-md
</style>
