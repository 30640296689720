<template>
    <div class="public">
        <SimpleHeader />

        <main>
            <div class="structure">
                <h1>404 - Page not found</h1>
                <p>Sorry, we couldn't find the page you were looking for.</p>
                <p class="button-wrapper">
                    <router-link to="/" class="button">
                        <span class="inner">Return to homepage</span>
                    </router-link>
                </p>
            </div>
        </main>

    </div>
</template>

<script>
import SimpleHeader from '@/components/layout/SimpleHeader.vue';

export default {
    name: 'NotFoundPage',
    components: {
        SimpleHeader
    }
};

</script>

<style scoped lang="sass">
.structure
    text-align: center

.button-wrapper
    margin-top: $site-padding-md
</style>
