<template>
    <div class="contact-details-block">
        <h3 v-if="title">{{ title }}</h3>
        <external-link :href="url" v-if="url">
            <svg class="icon">
                <use xlink:href="#globe"></use>
            </svg>
            {{ url }}
        </external-link>
        <a :href="'tel:'+telephone" class="phone" v-if="telephone">
            <svg class="icon">
                <use xlink:href="#phone"></use>
            </svg>
            {{ telephoneDisplay }}
        </a>
    </div>
</template>

<script>
export default {
    name: 'ContactDetailsBlock',
    props: {
        title: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        },
        telephone: {
            type: String,
            default: null
        }
    },
    computed: {
        telephoneDisplay: function () {
            return this.telephone.replace(/-/g, ' ');
        }
    }
};
</script>

<style lang="sass">
    .contact-details-block
        h3,
        .external-link-wrapper,
        .phone
            padding-bottom: $site-padding

        .external-link-wrapper
            margin: 0

        .external-link,
        .phone
            text-decoration: none
            word-break: break-word

            &:hover,
            &:focus,
            &:active
                text-decoration: underline

            .theme-braftovi &
                color: $plum

            .theme-mektovi &
                color: $teal-dark

        .external-link,
        .phone
            display: flex
            align-items: center

        .icon
            margin-right: $site-padding-xs
            flex-shrink: 0
</style>
