<template>
    <div>
        <h1>Cookie Policy</h1>
        <h2>Last reviewed March 2023</h2>
        <p>A cookie is a small file that can be placed on your device's hard drive or on a website server. We use cookies to obtain some of the automatically collected information described above. You do not have to accept a cookie, and can choose which cookie to accept by changing the parameters in your browser so that it will not accept cookies. For more information about cookies including how to set your internet browser to reject cookies or to better control the installation of cookies in your device, please go to:</p>
        <p>
            <a href="javascript:void(0)">Cookie settings for Internet Explorer</a>&nbsp;<br>
            <external-link href="http://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent?redirectlocale=en-US&amp;redirectslug=Cookies">Cookie settings for Firefox</external-link>&nbsp;<br>
            <external-link href="http://support.google.com/chrome/bin/answer.py?hl=fr&amp;answer=95647">Cookie settings for Google Chrome</external-link>&nbsp;<br>
            <external-link href="http://support.apple.com/kb/HT1677?viewlocale=fr_FR">Cookie settings for Safari</external-link>&nbsp;and&nbsp;<external-link href="http://support.apple.com/kb/HT1677?viewlocale=fr_FR">iOS</external-link>
        </p>
        <h2>Types of Cookies we use</h2>
        <ul>
            <li><strong>Functional cookies</strong>: these cookies enhance the performance and functionality of our Website, often as a result of something you’re doing as a user. For instance, we may personalize our content for you or remember your preferences.</li>
            <li><strong>Analysis cookies</strong>: these are cookies used to improve our Website, for example for analytics that let us see how our Website is being used and where to make improvements. These cookies are used to collect information about how visitors use our Website. The information is collected in an anonymous form and includes the number of visitors, where visitors have come from to the Website and the pages they visited.</li>
        </ul>
        <h2>You may find below the list of Cookies we use on our Website</h2>

        <div class="table-wrapper">
            <table cellpadding="0" cellspacing="0">
                <thead>
                    <tr>
                        <th width="20%">Type of cookie</th>
                        <th>Name of cookie</th>
                        <th>Purpose</th>
                        <th width="20%">Storage period</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Functional</td>
                        <td>pfMaterialsPatientConfirmed</td>
                        <td>Stores that the user has confirmed they are a patient</td>
                        <td>Current site session</td>
                    </tr>
                    <tr>
                        <td>Functional</td>
                        <td>pfMaterialsCookiesConfirmed</td>
                        <td>Stores that the user has accepted necessary cookies and saved their settings</td>
                        <td>30 days</td>
                    </tr>
                    <tr>
                        <td>Analysis</td>
                        <td>pfMaterialsAnalyticsCookiesConfirmed</td>
                        <td>Stores that the user has accepted Analysis cookies</td>
                        <td>30 days</td>
                    </tr>
                    <tr>
                        <td>Analysis</td>
                        <td>_gid and related Google Analytics cookies</td>
                        <td>This cookie name is asssociated with Google Universal Analytics, these cookies are stored if the user has accepted Analysis cookies.</td>
                        <td>13 months</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h2>Managing and Deleting Cookies</h2>
        <p>You can update the setting of cookies used on the site by clicking on the following button:</p>

        <cookie-modal />
    </div>
</template>

<script>
import CookieModal from '@/components/ui/CookieModal.vue';

export default {
    name: 'CookiePolicy',
    components: {
        CookieModal
    },
    metaInfo() {
        return {
            title: 'Cookie Policy',
            meta: [
                {
                    name: 'robots',
                    content: 'noindex, follow'
                }
            ]
        };
    }
};
</script>
