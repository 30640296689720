<template>
    <footer :class="theme" id="siteFooter">
        <div class="structure">
            <p class="notice" :class="{ 'global-notice':globalFooter }">This website has been developed and is fully funded by Pierre Fabre Limited. It is intended for patients in the UK and the Republic of Ireland who have been prescribed a Pierre Fabre oncology treatment.</p>
            <div class="extra-info">
                <p class="job-code">Job code: <span>{{ jobCode }}</span> {{ dateOfPrep }}</p>
                <ul class="legal-links">
                    <li><router-link target="_blank" :to="{ name: 'terms'}">Terms of use</router-link></li>
                    <li><router-link target="_blank" :to="{ name: 'privacy'}">Privacy policy</router-link></li>
                    <li><router-link target="_blank" :to="{ name: 'cookies'}">Cookie policy</router-link></li>
                    <li><router-link target="_blank" :to="{ name: 'accessibility'}">Website accessibility</router-link></li>
                </ul>
            </div>
        </div>
        <adverse-events v-if="adverseEvents" />
    </footer>
</template>

<script>
import AdverseEvents from '@/components/layout/AdverseEvents.vue';

export default {
    name: 'Footer',
    components: {
        AdverseEvents
    },
    data () {
        return {
            dateOfPrep: 'May 2023',
            jobCode: 'UK/MED/0028',
            jobCodeHoldingPage: 'UK/MED/0027'
        };
    },
    props: {
        adverseEvents: {
            type: Boolean,
            default: true
        },
        globalFooter: {
            type: Boolean,
            default: false
        },
        theme: String
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
    footer
        background: $navy
        color: $white
        position: fixed
        bottom: 0
        left: 0
        right: 0
        z-index: $zindex-footer
        padding: $site-padding-sm 0
        line-height: 1.5

        @supports(position: sticky)
            position: sticky

        @include media($media-width-medium)
            padding: $site-padding 0
            line-height: 1.7

        .extra-info
            flex-shrink: 0

            @include media($media-width-xxlarge)
                text-align: right

        .notice,
        .job-code
            font-size: $font-size-xsm-alt
            margin: 0

            @include media($media-width-medium)
                font-size: $font-size-sm

                &.large
                    font-size: $font-size-base

        .job-code
            span
                &::after
                    content: "|"
                    margin: 0 $site-padding-sm

                    @include media($media-width-500)
                        margin: 0 $site-padding-sm

        .notice
            font-weight: bold
            width: 100%
            max-width: 695px
            padding-right: $site-padding
            -webkit-font-smoothing: antialiased

            &.global-notice
                @include media($media-width-xxlarge)
                    max-width: 500px

            + .extra-info
                margin-top: $site-padding-sm

                @include media($media-width-xxlarge)
                    margin-top: 0

        .drug-site-main &
            padding: $site-padding

            @include media($media-width-medium)
                padding: $site-padding $site-padding-md

            .structure
                padding: 0

            @include media($media-ie11)
                position: absolute
                bottom: 0
                left: 0

        &.braftovi
            background: $theme-braftovi

        &.mektovi
            background: $theme-mektovi

        &.nerlynx,
        &.theme-nerlynx
            background: $theme-nerlynx

        .structure
            display: flex
            flex-direction: column
            align-items: flex-start
            justify-content: space-between

            @include media($media-width-xxlarge)
                flex-direction: row
                align-items: center

        &.footer-404
            .notice
                max-width: 100%
                text-align: center

        .legal-links
            padding: 0
            margin: 0
            margin-top: 10px
            font-size: $font-size-xsm-alt

            @include media($media-width-medium)
                margin-top: 0
                margin-bottom: -$site-padding-sm
                font-size: $font-size-sm

            @include media($media-width-xxlarge)
                padding: 0
                margin-bottom: 0

            a
                color: #fff !important
                font-weight: normal
                padding: 10px
                display: inline-block

            li
                display: inline-block
                padding: 0

                &::before
                    display: none

                &::after
                    content: "|"
                    margin: 0 $site-padding-xs

                &:first-child
                    a
                        padding-left: 0

                &:nth-child(2),
                &:last-child
                    &::after
                        display: none

                    @include media($media-width-380)
                        display: inline

                &:nth-child(2)::after
                    @include media($media-width-380)
                        display: inline

                &:nth-child(3)
                    a
                        padding-left: 0

                        @include media($media-width-380)
                            padding-left: 10px

                &:last-child
                    a
                        padding-right: 0
</style>
