<template>
    <div>
        <h1>Terms of Use for Pierre Fabre Oncology UK and Republic of Ireland Patient Website</h1>
        <h2>Last reviewed March 2023</h2>
        <h3>1. Website Terms of Use</h3>
        <p>By accessing, browsing or otherwise using this Website you agree to be bound by these terms of use (the “Terms of Use”) and by the terms of our Privacy Policy.</p>
        <p>If you do not wish to accept these Terms of Use, we request that you leave this Website. </p>

        <p>This Website is intended to be used by residents of the United Kingdom and of the Republic of Ireland who are 18 or over who have been prescribed a Pierre Fabre medicine by their NHS or any other treatment team (“you/your”). </p>
        <p>If you are not a resident of the United Kingdom or of the Republic of Ireland aged 18 or over, please access this Website only in the presence of one of your parents or a guardian who will agree to be bound by these Terms of Use.</p>
        <p>If you are not a resident of the United Kingdom or of the Republic of Ireland, please refer to a Pierre Fabre website in your country of residence.</p>
        <p>If at your own initiative you choose to access this Website even if you are not a resident of the United Kingdom or of the Republic of Ireland aged 18 or over, you accept responsibility for this choice and for compliance with local laws.</p>

        <h3>2. Pierre Fabre in the UK and in the Republic of Ireland</h3>
        <p>This Website is controlled and operated by Pierre Fabre Limited (referred to in these terms of use as “Pierre Fabre”, “we”, “us” or “our”). Our trading address is Ground Floor, 250 Longwater Avenue, Green Park, Reading, RG2 6GP and our registered office address is One Fleet Place, London, EC4M 7WS.   Pierre Fabre is a limited liability company registered in England, with company registration number 00986738.</p>

        <p>You may <a href="mailto:privacy@pierre-fabre.co.uk">contact us here</a> if you have any questions or feedback about this Website.</p>

        <h3>3. Content of this Website and Personal Use by You</h3>
        <p>The information contained in this Website, including text, images, audio, and video ("Content") is provided for your personal general information purposes only and not to provide advice on healthcare, treatment or for any other purpose. References to medicines or products are not intended to provide a medical diagnosis or advice. If you have a medical condition, please contact your GP or treatment team. </p>
        <p>We may add, remove or update to the Content on the Website at any time without notice to you.</p>
        <p>When accessing or browsing this Website, you may access, download or use Content only for your personal information and not for commercial use. You are not authorised to distribute, modify, transmit, re-use, re-post, or otherwise use the Content for commercial purposes, without the prior written permission of Pierre Fabre. </p>
        <p>All Content on this Website is copyrighted unless otherwise noted and may only be used in accordance with these Terms of Use. Pierre Fabre neither warrants nor represents that your use of Content will not infringe rights of third parties. Save for permission to make personal use of the Content in accordance with this Clause 3, you are not granted a license or other rights in the Content. </p>
        <p>If we become aware that you have not made use of this Website in accordance with these Terms of Use, we may at any time and without notice take corrective action, including preventing you from accessing this Website, prohibiting you from using the Content and removing any information, data and content posted by You on the Website. Pierre Fabre may, at its sole discretion, seek to recover damages from you for any losses or damage caused to Pierre Fabre as a result of use of this Website by you that is not in accordance with these Terms of Use.</p>

        <h3>4. Brands, trademarks, intellectual property and other proprietary rights</h3>
        <p>All the images and texts available on this Website, together with all the product or brand names included in the Content, are the exclusive property of Pierre Fabre Group or of their subsidiaries or are held by them under licence.</p>
        <p>Pierre Fabre does not grant you a license to or right in any such trademarks, patents, trade secrets, technologies, products, processes and other proprietary rights of Pierre Fabre group companies.</p>

        <h3>5. Disclaimers</h3>
        <p>Pierre Fabre uses reasonable efforts to ensure that the Content is accurate and up to date and makes no warranties in this respect. Content provided by Pierre Fabre or by third parties may contain technical inaccuracies or typographical errors.  Pierre Fabre shall not be liable or responsible for any errors or omissions in the Content of the Website.</p>
        <p>YOUR USE OF THIS WEBSITE AND ITS CONTENT IS AT YOUR OWN RISK.  PIERRE FABRE WILL NOT BE LIABLE, WHETHER IN TORT (INCLUDING FOR NEGLIGENCE OR BREACH OF STATUTORY DUTY), IN CONTRACT OR OTHERWISE, FOR: (1) LOSS OR DAMAGE WHICH MAY ARISE FROM RELIANCE ON CONTENT OF THIS WEBSITE; (2) ANY LOSS, DAMAGE OR COSTS SUFFERED OR INCURRED BY YOU ARISING FROM VIRUSES OR OTHER UNAUTHORISED ELEMENTS RECEIVED VIA THIS WEBSITE THAT CAUSE INTERRUPTION OR OTHERWISE AFFECT YOUR DEVICE; AND (3) ANY LOSS OF PROFITS; LOSS OF BUSINESS; LOSS OF GOODWILL OR SIMILAR LOSSES; LOSS OF ANTICIPATED SAVINGS; LOSS OF CONTRACTS; LOSS OF USE; LOSS OR CORRUPTION OF DATA OR INFORMATION; OR ANY SPECIAL, INDIRECT, CONSEQUENTIAL OR PURE ECONOMIC LOSS INCURRED BY YOU OR ANY USER IN ACCESSING THIS WEBSITE.  ALL USE BY YOU OF CONTENT SHOULD BE CAREFULLY EVALUATED BY YOU AS PIERRE FABRE AND ANY BUSINESS INVOLVED IN CREATING, PRODUCING OR DELIVERING THE CONTENT SHALL NOT BE LIABLE FOR ANY DIRECT, INCIDENTAL, CONSEQUENTIAL, INDIRECT OR LOSS OR DAMAGES ARISING OUT OF ACCESS TO, USE OF OR INABILITY TO USE THIS WEBSITE, OR ANY ERRORS OR OMISSIONS IN THE CONTENT, AND ALL LIABILITY FROM THE SAME IS EXPRESSLY EXCLUDED, IN EACH CASE TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW.</p>

        <h3>6. Content you supply to us</h3>
        <p>Except for information covered by our Privacy Policy, any communication or material you transmit to the Website by electronic mail or otherwise, including any data, questions, comments, suggestions or the like is, and will be treated as, non-confidential and non-proprietary. Anything you transmit or post becomes the property of Pierre Fabre and may be used for any purpose, including, but not limited to, reproduction, disclosure, transmission, publication, broadcast and posting. Furthermore, Pierre Fabre is free to use, without compensation to you, any ideas, concepts, know-how, or techniques contained in any communication you send to the Website for any purpose whatsoever including, but not limited to, developing, manufacturing and marketing products using such information.</p>

        <h3>7. No promotion</h3>
        <p>You must not construe anything on this Website as a promotion or advertisement for any product or for the use of any product of Pierre Fabre. Nothing on this Website constitutes an invitation or offer to invest or deal in any shares or securities of companies of the Pierre Fabre Group.</p>

        <h3>8. Hypertext Links to and from this Website</h3>
        <p>Pierre Fabre has not reviewed any of the third-party websites which contain links to this Website and is not responsible for, does not recommend and accepts no liability for any information or opinion contained in any third-party website. You may not link any pages of this Website to other sites without the prior written consent of Pierre Fabre.</p>
        <p>Links to third-party sites from this Website may be provided for the interest or convenience of visitors to this Website. We will endeavour to inform you when you are leaving this Website that the terms of use and privacy policy of the third-party site may be different.</p>
        <p>Pierre Fabre has no ownership in, nor any affiliation with, any third-party trademarks appearing on this Website. Such third-party trademarks are used only to identify their respective owners.</p>

        <h3>9. Third party rights</h3>
        <p>No provision of these Terms of Use shall be enforceable by any third party under the Contracts (Rights of Third Parties) Act 1999, save for any company or representative of the Pierre Fabre Group.</p>

        <h3>10. Governing law and jurisdiction</h3>
        <p>These Terms of Use and any dispute or claim arising out of or in connection with their interpretation shall be governed by and construed in accordance with the laws of England. The courts of England shall have the exclusive jurisdiction to settle any dispute or claim (including non-contentious claims) that arises out of or in connection with the use of this Website, Content or these Terms of Use.</p>
    </div>
</template>

<script>
export default {
    name: 'TermsOfUse',
    metaInfo() {
        return {
            title: 'Terms of Use',
            meta: [
                {
                    name: 'robots',
                    content: 'noindex, follow'
                }
            ]
        };
    }
};
</script>
