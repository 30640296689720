<template>
    <div class="public">
        <CookieBar />

        <SimpleHeader />

        <main>
            <div class="structure">
                <div class="two-col">
                    <div>
                        <h1>Thank you for visiting the Pierre Fabre Oncology UK and Republic of Ireland Patient Website</h1>
                        <h2>This site is currently undergoing maintenance.</h2>
                        <p><strong>We are working to resolve this issue as quickly as possible and apologise for any inconvenience.</strong></p>
                        <p>In the meantime, you can access independent charities by clicking on the links found in the right-hand panel of this page, which may be of help to people who are living with cancer.</p>
                        <p><strong>If you are on treatment and have any questions or concerns, please contact your treatment team.</strong></p>
                        <p>By clicking on any of the links on this page, you will leave the Pierre Fabre Oncology UK and Republic of Ireland Patient Website (www.patient.pierre-fabre-oncology.co.uk). These links will take you to website over which Pierre Fabre Limited has no ownership or control. Pierre Fabre Limited accepts no responsibility for the content, accuracy, or your use of linked websites.</p>
                        <p>For Medical Information enquiries please contact the Pierre Fabre Ltd Medical Information Team on <a href="#">0800 085 5292</a> in the UK or <a href="#">1800 812 464</a> in the Republic of Ireland, or by email at <a href="mailto:UK_MI@pierre-fabre.com">UK_MI@pierre-fabre.com</a> or <a href="mailto:IE_MI@pierre-fabre.com">IE_MI@pierre-fabre.com</a>.</p>
                    </div>
                    <LinkList :links="links" />
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import SimpleHeader from '@/components/layout/SimpleHeader.vue';
import LinkList from '@/pages/HoldingPage/LinkList.vue';

export default {
    name: 'HoldingPage',
    components: {
        SimpleHeader,
        LinkList
    },
    data () {
        return {
            dateOfPrep: 'March 2023',
            links: [
                {
                    title: 'For patients in the UK',
                    content: [
                        {
                            name: 'Cancer Research UK',
                            href: 'https://www.cancerresearchuk.org/about-cancer',
                            image: 'cruk-logo.png'
                        },
                        {
                            name: 'Macmillan',
                            href: 'https://www.macmillan.org.uk/',
                            image: 'macmillan-cancer-support.png'
                        },
                        {
                            name: 'Marie Curie',
                            href: 'https://www.mariecurie.org.uk/',
                            image: 'marie-curie-logo.svg'
                        }
                    ]
                },
                {
                    title: 'For patients in Republic of Ireland',
                    content: [
                        {
                            name: 'Marie Keating Foundation',
                            href: 'https://www.mariekeating.ie/',
                            image: 'marie-keating-logo.png'
                        },
                        {
                            name: 'Irish Cancer Society',
                            href: 'https://www.cancer.ie/',
                            image: 'irish-cancer-society.png'
                        }
                    ]
                }
            ]
        };
    },
    metaInfo() {
        return {
            titleTemplate: null,
            meta: [
                {
                    name: 'robots',
                    content: 'noindex, follow'
                }
            ]
        };
    }
};
</script>
