<template>
    <div class="public">
        <cookie-bar></cookie-bar>

        <SimpleHeader />

        <main>
            <div class="structure">
                <back-to-home />
                <accessibility-statement>
                    <p>This Website is intended for patients in the UK and Republic of Ireland who want to know more about the Pierre Fabre Oncology medicine(s), which are prescribed for them by their treatment team.</p>
                </accessibility-statement>
                <div class="page-job-code">Job code: {{ $route.meta.jobCode }} | {{ dateOfPrep }}</div>
            </div>
        </main>
    </div>
</template>

<script>
import SimpleHeader from '@/components/layout/SimpleHeader.vue';
import BackToHome from '@/components/layout/BackToHome.vue';
import AccessibilityStatement from '@/components/legal/AccessibilityStatement.vue';

export default {
    name: 'publicAccessibilityPage',
    components: {
        BackToHome,
        SimpleHeader,
        AccessibilityStatement
    },
    data () {
        return {
            dateOfPrep: 'March 2023'
        };
    }
};
</script>
