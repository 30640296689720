<template>
    <section class="alert-block">
        <svg class="icon-warning round" v-if="rounded">
            <use xlink:href="#warning-round"></use>
        </svg>
        <svg class="icon-warning" v-else>
            <use xlink:href="#warning"></use>
        </svg>
        <slot></slot>
    </section>
</template>

<script>
export default {
    props: {
        rounded: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped lang="sass">
.alert-block
    border: 1px solid $cherry
    border-radius: 5px
    padding: 30px $site-padding-md
    display: flex
    flex-direction: column
    align-items: center

    @include media($media-width-medium)
        flex-direction: row
        padding: 30px 40px

    .theme-nerlynx &
        border-radius: 20px
        border: 3px solid $magenta-dusky
        justify-content: center

        @include media($media-width-medium)
            border-radius: 60px

        @include media($media-width-large)
            border-radius: 92px

        p
            font-weight: $font-bold

.icon-warning
    width: 75px
    height: 75px
    flex-shrink: 0
    margin-bottom: $site-padding

    &.round
        width: 64px
        height: 64px

        @include media($media-width-medium)
            width: 48px
            height: 48px

    .theme-nerlynx &
        color: $magenta-dusky

    @include media($media-width-medium)
        margin-bottom: 0
        margin-right: $site-padding-sm
</style>
