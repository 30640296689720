<template>
    <div>
        <accordion-block :items="links" class="link-list" v-slot="item">
            <external-link v-for="i in item.content" :href="i.href" :key="i.index" :class="i.class">
                <div class="image-wrapper" v-if="i.image">
                    <img :src="require('@/assets/images/' + i.image + '')" :alt="i.name + ' logo'">
                </div>
                <span>{{ i.name }}</span>
            </external-link>
        </accordion-block>
    </div>
</template>

<script>
export default {
    name: 'LinkList',
    props: {
        links: {
            type: Array
        }
    }
};
</script>

<style lang="sass">
.link-list
    .external-link-wrapper
        width: 100%

        + .external-link-wrapper
            margin-top: $site-padding-sm

    a
        display: flex
        align-items: center
        justify-content: space-between
        font-size: $font-size-base
        color: $text-color
        text-decoration: none

        @include media($media-width-small)
            font-size: 18px

        @include media($media-width-medium)
            font-size: 20px

        &::after
            content: ''
            width: 26px
            height: 15px
            background-image: url(~@/assets/images/link-arrow.svg)
            background-size: 100%
            background-repeat: no-repeat
            opacity: 0.5
            margin-left: 20px
            transform: translateX(0)
            transition: transform 0.2s
            flex-shrink: 0

        &:hover
            &::after
                transform: translateX(6px)

    .accordion-header,
    .accordion-content
        padding: $site-padding

        @include media($media-width-medium)
            padding: $site-padding-md $site-padding-lg
            padding-right: $site-padding


    .accordion-header
        background: $sand

        .theme-nerlynx &
            background: $nerlynx-sand

        h2.title
            font-size: 18px
            color: $navy
            font-weight: $font-normal

            .theme-nerlynx &
                color: $theme-nerlynx

            @include media($media-width-small)
                font-size: 20px

            @include media($media-width-medium)
                font-size: 22px

    .accordion-item
        border: 0
        &.open
            .accordion-header
                background: $sand

                .theme-nerlynx &
                    background: $nerlynx-sand

                h2.title
                    font-weight: $font-bold

    .image-wrapper
        text-align: center
        margin-right: $site-padding
        flex-shrink: 0
        width: 120px
        display: none

        @include media($media-width-500)
            display: block

    img
        height: auto
        max-height: 62px
        width: auto

    span
        flex-grow: 1

    // Temporary class for links that haven't been signed off yet
    .temporary-disable-link
        pointer-events: none

        a
            margin-top: 20px

            &::after
                content: none

</style>
