<template>
    <transition name="modal">
        <div class="modal modal-overlay" v-show="show">
            <div class="modal-content">
                <slot name="close-btn"></slot>

                <h2 class="modal-title">
                    <slot name="title"></slot>
                </h2>

                <div class="modal-body">
                    <slot name="body"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        show: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="sass">
    $modal-side-padding: 30px
    $modal-border-radius: 5px

    .modal
        margin-top: 0 !important

    .modal-title
        color: $navy
        font-size: 22px
        margin: 0
        padding-right: 20px

    .modal-body
        max-height: 70vh
        overflow: auto

    .modal-overlay
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        background: rgba($navy, 0.9)
        transition: opacity 0.1s linear
        z-index: $zindex-modal

    .modal-content
        background: #fff
        width: 90%
        max-width: 800px
        text-align: left
        padding: $site-padding
        position: relative

        @include media($media-width-medium)
            padding: $site-padding-md
            text-align: center

        @include media($media-width-large)
            width: 70%
            padding: $site-padding-lg

    button.button-modal-close
        // override usual button styles
        background: transparent !important
        box-shadow: none !important
        border: 0
        position: absolute
        top: 0
        right: 10px
        padding-right: 0

        .icon
            fill: $navy
            transition: opacity 0.2s

        @include media($media-width-small)
            top: auto
            bottom: 100%
            right: 0

            .icon
                fill: #fff

        &:hover,
        &:focus
            background: transparent
            outline: none

            .icon
                opacity: 0.5

    // Animations
    .modal-enter,
    .modal-leave-active
        opacity: 0

    .theme-nerlynx
        + .vue-portal-target
            .modal-overlay
                background-color: rgba($magenta, 0.9)

            .modal-title
                color: $magenta

            button.button-border
                background: $white
                border: 2px solid $magenta
                color: $text-color
                box-shadow: none
                transition: box-shadow $transition

                .inner
                    background: $white

                &:hover,
                &:active,
                &:focus
                    box-shadow: 0 6px 18px 0 rgba($magenta, 0.27)

            .button-confirm
                background: $button-nerlynx
                border-color: $button-nerlynx
                box-shadow: none

                .inner
                    background: $button-nerlynx

                &:hover,
                &:active,
                &:focus
                    background: $button-hover-nerlynx
                    border-color: $button-hover-nerlynx

                    .inner
                        background: $button-hover-nerlynx

</style>
