import Vue from 'vue';
import VueRouter from 'vue-router';

import HoldingPage from '@/pages/HoldingPage';

import PublicTermsPage from '@/pages/PublicTermsPage';
import PublicPrivacyPage from '@/pages/PublicPrivacyPage';
import PublicCookiePage from '@/pages/PublicCookiePage';
import PublicAccessibilityPage from '@/pages/PublicAccessibilityPage';

import NotFound from '@/pages/NotFound';

Vue.use(VueRouter);

const baseRoutes = [
    {
        path: '/',
        name: 'HoldingPage',
        component: HoldingPage,
        meta: {
            requiresGlobalFooter: true
        }
    },
    {
        path: '/terms-of-use',
        name: 'terms',
        component: PublicTermsPage,
        meta: {
            jobCode: 'UK/MED/0019',
            requiresGlobalFooter: true
        }
    },
    {
        path: '/privacy-policy',
        name: 'privacy',
        component: PublicPrivacyPage,
        meta: {
            jobCode: 'UK/MED/0021',
            requiresGlobalFooter: true
        }
    },
    {
        path: '/cookie-policy',
        name: 'cookies',
        component: PublicCookiePage,
        meta: {
            jobCode: 'UK/MED/0018',
            requiresGlobalFooter: true
        }
    },
    {
        path: '/accessibility',
        name: 'accessibility',
        component: PublicAccessibilityPage,
        meta: {
            jobCode: 'UK/MED/0020',
            requiresGlobalFooter: true
        }
    },
    {
        path: '*',
        name: 'Not Found',
        component: NotFound,
        meta: {
            requiresGlobalFooter: true
        }
    }
];

const routes = baseRoutes;

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

export default router;
