<template>
    <div>
        <h1>Website Accessibility</h1>
        <h2>Last reviewed March 2023</h2>

        <slot></slot>

        <p>Pierre Fabre Limited want everyone who visits this Website to find the information they were looking for and to find this experience positive. For this purpose we apply the Web Content Accessibility Guidelines (WCAG) 2.0.</p>

        <p>These guidelines explain how to make web content more accessible for people with disabilities, and user friendly for everyone. It is our aim that information on this Website meets or exceeds the requirements of the level A criteria of the WCAG 2.0 guidelines.</p>

        <p>To give your feedback regarding use of this Website, please <a href="mailto:privacy@pierre-fabre.co.uk">contact us here</a>.</p>

        <h2>Browsers</h2>
        <p>This Website follows W3C standards for HTML and CSS. It should display correctly in the current and previous two versions of the following browsers: Firefox, Chrome, Edge, Safari, and Opera.</p>

        <p>Content of this Website is designed to be accessible on the screens of most devices including iPhone, iPad, or Android phones.</p>

        <h2>Text and alternative text for images</h2>
        <p>You can vary the text size by using your browser’s text resize option: in many web browsers this can be accessed by selecting “View” -> “Text Size” or by pressing Ctrl + and Ctrl - on your keyboard.</p>

        <p>Images have been given alternative text so that devices can “read” them.</p>

        <h2>Contrast</h2>
        <p>We have reviewed text and background colour combinations, to provide good contrast. Information is referenced by various indicators including colour.</p>
    </div>
</template>

<script>
export default {
    name: 'AccessibilityStatement',
    metaInfo() {
        return {
            title: 'Accessibility',
            meta: [
                {
                    name: 'robots',
                    content: 'noindex, follow'
                }
            ]
        };
    }
};
</script>
