<template>
    <header class="site-header">
        <div class="structure">
            <a href="/">
                <img alt="Pierre Fabre" src="@/assets/images/logo.svg" class="logo">
            </a>
        </div>
    </header>
</template>

<script>
export default {
    name: 'SimpleHeader',
    props: {
        msg: String
    }
};
</script>

<style lang="sass">
    .site-header,
    .cookie-bar-header
        text-align: center

        .structure
            &::after
                content: ''
                display: block
                height: 1px
                width: 100%
                background: $sand

        .logo
            width: 110px
            height: auto
            padding: 20px 0

            @include media($media-width-medium)
                width: 150px
                padding: 30px 0

            @include media($media-width-large)
                width: 196px
                padding: 40px 0

    // add some padding when we have cookie consent showing
    // so legal pages can still be read
    .cookie-consent
        + header
            padding-top: 320px

            @include media($media-width-medium)
                padding-top: 250px

            @include media($media-width-large)
                padding-top: 130px

</style>
