<template>
    <div class="adverse-events">
        <div class="structure">
            <div class="ae-tab" role="button" @click="toggleAdverseEvents">
                Reporting side effects
                <svg class="icon" :class="{ 'open' : isOpen }">
                    <use xlink:href="#chevron"></use>
                </svg>
            </div>
            <slide-up-down :active="isOpen">
                <div class="info">
                    <div class="copy">
                        <p>You can help by reporting any side effects you may get. If you get any side effects, talk to your doctor, pharmacist or nurse. This includes any possible side effects not listed in the package leaflet. If you are in the UK, you can report side effects directly via the Yellow card Scheme at <external-link href="http://www.mhra.gov.uk/yellowcard">www.mhra.gov.uk/yellowcard</external-link> or by searching for MHRA Yellow Card in the Google Play or Apple App stores. If you are in Ireland, adverse events should be reported to HPRA at <external-link href="https://www.hpra.ie/homepage/about-us/report-an-issue/human-adverse-reaction-form">www.hpra.ie</external-link> or by email at <a href="mailto:medsafety@hpra.ie">medsafety@hpra.ie</a>. Adverse events can also be reported to the Pharmacovigilance Department of Pierre Fabre Limited on 0800 085 5292 in the UK or 1800 812 464 in the Republic of Ireland, or by email at <a href="mailto:UK_MI@pierre-fabre.com">UK_MI@pierre-fabre.com</a> or <a href="mailto:IE_MI@pierre-fabre.com">IE_MI@pierre-fabre.com</a>.</p>
                    </div>
                    <button class="button-theme small" @click="toggleAdverseEvents">
                        <span class="inner">Close</span>
                    </button>
                </div>
            </slide-up-down>
        </div>
    </div>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down';

export default {
    name: 'AdverseEvents',
    components: {
        SlideUpDown
    },
    data () {
        return {
            isOpen: true
        };
    },
    methods: {
        toggleAdverseEvents () {
            this.isOpen = !this.isOpen;
        }
    }
};
</script>

<style scoped lang="sass">
    .adverse-events
        position: absolute
        bottom: 100%
        left: 0
        right: 0
        width: 100%
        background: #fff
        color: $landing-grey
        box-shadow: 0 3px 50px -14px rgba(#000, 0.37)
        border-top: 1px solid lighten($graphite, 50%)

        a
            font-weight: normal

        > .structure
            position: relative

            @include media($media-width-large)
                .drug-site-main &
                    padding: 0 $site-padding-md

    .info
        padding: $site-padding-sm 0
        display: flex
        flex-direction: column
        align-items: flex-start
        border-top: 1px solid $landing-border-color
        max-height: 40vh
        overflow-y: auto

        @include media($media-width-medium)
            padding: $site-padding 0

        p
            font-size: $font-size-xsm-alt

            @include media($media-width-medium)
                font-size: $font-size-sm

            &.medicine-disclaimer
                font-size: $font-size-base

        .copy
            max-width: 100%

        button
            flex-shrink: 0
            margin-top: $site-padding-sm

            @include media($media-width-500)
                margin-top: $site-padding-sm

        @include media($media-width-medium)
            align-items: flex-end
            border-top: 0

            .drug-site-main &
                align-items: flex-start

                button
                    margin-top: $site-padding

        @include media($media-width-medium)
            flex-direction: row

            button
                margin-top: 0
                margin-left: $site-padding

            .drug-site-main &
                flex-direction: column
                align-items: flex-start

                button
                    margin-top: $site-padding
                    margin-left: 0

    .ae-tab
        background: #fff
        padding: 12px $site-padding
        box-shadow: 0 -6px 20px -14px rgba(#000, 0.9)
        border: 1px solid lighten($graphite, 50%)
        border-bottom: 0
        position: absolute
        bottom: 100%
        left: 0
        font-weight: bold
        display: flex
        align-items: center
        width: 100%

        @include media($media-width-medium)
            width: auto
            left: $site-padding-md

        @include media($media-width-large)
            left: $site-padding-lg

            .drug-site-main &
                left: $site-padding-md

        .icon
            width: 14px
            height: 8px
            margin-left: 10px
            transition: transform 0.2s ease-in-out
            fill: $navy

            .theme-braftovi &
                fill: $theme-braftovi

            .theme-mektovi &
                fill: $theme-mektovi

            .theme-nerlynx &
                fill: $theme-nerlynx

            &.open
                transform: rotate(180deg)

        a
            display: inline-block
            padding: 15px 25px

            @include media($media-width-500)
                display: inline
                padding: 0

    .theme-braftovi
        .button-theme
            .inner
                background: $button-braftovi

            &:hover
                .inner
                    background: $button-hover-braftovi

    .theme-mektovi
        .button-theme
            .inner
                background: $button-mektovi

            &:hover
                .inner
                    background: $button-hover-mektovi

    .theme-nerlynx
        .button-theme
            .inner
                background: $button-nerlynx

            &:hover
                .inner
                    background: $button-hover-nerlynx

    .public
        + footer
            .adverse-events
                button
                    font-weight: $font-bold
                    text-transform: uppercase

</style>
