<template>
    <div class="external-link-wrapper">
        <a :href="href" @click.prevent="openExternalLink" class="external-link">
            <slot></slot>
        </a>
        <portal to="modal-area">
            <Modal :show="disclaimerShown" class="external-link-disclaimer" @close="closeDisclaimerModal" role="dialog" aria-label="You are about to leave this website">
                <template v-slot:close-btn>
                    <button class="button-modal-close" @click="closeDisclaimerModal">
                        <svg class="icon">
                            <title>Close</title>
                            <use xlink:href="#close"></use>
                        </svg>
                    </button>
                </template>
                <template v-slot:title>
                    You are about to leave this website.
                </template>
                <template v-slot:body>
                    <p>You are now leaving the Pierre Fabre Oncology UK and Republic of Ireland website (www.patient.pierre-fabre-oncology.co.uk). This link will take you to a website over which Pierre Fabre Limited has no ownership or control. Pierre Fabre accepts no responsibility for the content, accuracy, or your use of linked websites.</p>
                    <div class="button-wrapper">
                        <button class="button button-border small" @click="closeDisclaimerModal">
                            <span class="inner">CANCEL</span>
                        </button>
                        <a :href="href" class="button button-confirm small external-link-continue" target="_blank" rel="noopener noreferrer" @click="closeDisclaimerModal">
                            <span class="inner">PROCEED TO EXTERNAL SITE</span>
                        </a>
                    </div>
                </template>
            </Modal>
        </portal>
    </div>
</template>

<script>
import Modal from '@/components/ui/Modal.vue';

export default {
    name: 'ExternalLink',
    components: {
        Modal
    },
    data () {
        return {
            disclaimerShown: false
        };
    },
    props: {
        href: {
            type: String,
            default: ''
        }
    },
    methods: {
        closeDisclaimerModal () {
            this.disclaimerShown = false;
            document.body.classList.remove('overlay-open');
        },
        openExternalLink () {
            this.disclaimerShown = true;
            document.body.classList.add('overlay-open');
        }
    },
    mounted: function () {
        document.addEventListener('keydown', (e) => {
            if (this.disclaimerShown && e.keyCode === 27) {
                this.closeDisclaimerModal();
            }
        });
    }
};
</script>

<style scoped lang="sass">
    // we want this to be in the flow like a normal link
    // even though it holds the link and the modal
    .external-link-wrapper
        display: inline

    .external-link
        .adverse-events &
            font-weight: normal

    .external-link-disclaimer
        p
            font-size: $font-size-sm

            @include media($media-width-medium)
                font-size: $font-size-base

    .button-confirm
        order: -1
        margin-bottom: $site-padding-sm
        color: $white !important

        &:hover
            text-decoration: none

        @include media($media-width-small)
            margin-bottom: $site-padding

    .button-wrapper
        display: flex
        justify-content: center
        flex-direction: column
        margin-top: $site-padding
        padding-bottom: 20px

        @include media($media-width-medium)
            flex-direction: row
            margin-top: $grid-spacing

            .button
                flex-basis: auto
                flex-grow: 0
                flex-shrink: 0

                .inner
                    min-width: 150px

                + .button
                    margin-left: 30px

            .button-confirm
                order: 1
                margin-bottom: 0

    .resource-block
        .external-link
            display: flex
            align-items: center

        .external-link,
        .external-link:visited
            color: $white
            text-decoration: none

</style>
